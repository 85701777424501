const Themes = {
  'Default' : {
    appBackground: 'white',
    appText: '#3a3a3a',
    background: 'white',
    buttonMixed: '#4a4a4a',
    buttonOff: '#8a8a8a',
    buttonOn: '#ffbc00',
    menuIcon: '#666',
    menuIconSelected: '#ffbc00',
    overlayBackground: 'rgba(255, 255, 255, .85)',
    overlayText: '#666',
    text: '#666',
    textMixed: 'white',
    textOff: 'white',
    textOn: '#3E2723',
    unlockedBackground: '#eeeeee'
  },
  'Lighter' : {
    appBackground: 'white',
    appText: '#3a3a3a',
    background: 'white',
    buttonMixed: '#808080',
    buttonOff: '#eee',
    buttonOn: '#ffbc00',
    menuIcon: '#666',
    menuIconSelected: '#ffbc00',
    overlayBackground: 'rgba(255, 255, 255, .85)',
    overlayText: '#666',
    text: '#808080',
    textMixed: '#808080',
    textOff: '#808080',
    textOn: 'white',
    unlockedBackground: '#eeeeee'
  },
  'Light Blue' : {
    appBackground: 'white',
    appText: 'black',
    background: '#99CCFF',
    buttonMixed: '#4a4a4a',
    buttonOff: '#8a8a8a',
    buttonOn: '#3366FF',
    menuIcon: '#666',
    menuIconSelected: '#3366FF',
    overlayBackground: 'rgba(255, 255, 255, .85)',
    overlayText: '#666',
    text: 'black',
    textMixed: 'white',
    textOff: 'white',
    textOn: 'white',
    unlockedBackground: '#eeeeee'
  },
  'Light Green' : {
    appBackground: 'white',
    appText: 'black',
    background: '#66FF99',
    buttonMixed: '#4a4a4a',
    buttonOff: '#8a8a8a',
    buttonOn: '#009900',
    menuIcon: '#666',
    menuIconSelected: '#009900',
    overlayBackground: 'rgba(255, 255, 255, .85)',
    overlayText: '#666',
    text: 'black',
    textMixed: 'white',
    textOff: 'white',
    textOn: 'white',
    unlockedBackground: '#eeeeee'
  },
  'Dark Grey' : {
    appBackground: '#303030',
    appText: '#F5F5F5',
    background: '#424242',
    buttonMixed: '#607D8B',
    buttonOff: '#616161',
    buttonOn: '#607D8B',
    menuIcon: '#607D8B',
    menuIconSelected: '#F5F5F5',
    overlayBackground: 'rgba(66, 66, 66, 0.85)',
    overlayText: '#90A4AE',
    text: '#F5F5F5',
    textMixed: '#F5F5F5',
    textOff: '#F5F5F5',
    textOn: '#F5F5F5',
    unlockedBackground: 'repeating-linear-gradient(45deg, #607D8B, #607D8B 5px, #90A4AE 10px, #90A4AE 20px)'
  },
  'Dark' : {
    appBackground: '#636363',
    appText: '#dadada',
    background: 'rgba(37, 37, 37, .1)',
    buttonMixed: 'rgba(37, 37, 37, .9)',
    buttonOff: 'rgba(37, 37, 37, .4)',
    buttonOn: 'rgba(37, 37, 37, .9)',
    menuIcon: 'rgba(33, 33, 33, .9)',
    menuIconSelected: '#dadada',
    overlayBackground: 'rgba(68, 68, 68, .8)',
    overlayText: '#dadada',
    text: '#dadada',
    textMixed: '#dadada',
    textOff: '#dadada',
    textOn: '#dadada',
    unlockedBackground: 'rgba(51, 51, 51, .9)'
  }
}
export default Themes
